<template>
  <div>
    <v-card-title v-if="data.finances && data.finances.length > 0" dense flat style="padding-top: 0; padding-bottom: 0;">
      <h3>{{config.financeName}} do(a) {{config.idTitle}}</h3>
      <v-spacer></v-spacer>
      <h2></h2>
      <v-btn 
      :disabled="data.stage != 1 || data.paid" 
      small outline color="primary" 
      @click="$WiEditDialog({wiConfig: config.store + '-finance', onSubmit: refresh, data: parseParamsToFinanceModal(data)})" 
      >
      <v-icon>edit</v-icon>
      <span>Editar Pagamentos</span>
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <div v-if="data.finances && data.finances.length > 0">
      <v-data-table dense :headers="headersFinances" :items="data.finances" class="elevation-0" hide-actions >
        <template v-slot:headers="props">
          <tr style="height: 40px;">
            <th class="hidden-xs-only hidden-sm-only" v-for="header in props.headers" :key="header.text" style="text-align: center !important; margin: 0 !important; padding: 0px !important;">
              {{ header.text }}
            </th>
          </tr>
        </template>
        <template v-slot:items="props">
          <tr>
            <td class="justify-right layout px-0" style="margin-top: 0px;">
              <v-btn 
              :disabled="data.stage == 3 || (props.item.finance && props.item.finance.status != 1)" 
              @click="$WiEditDialog({wiConfig: config.store + '-finance', onSubmit: refresh, data: props.item})" 
              icon class="mx-0"
              >
                <v-icon>edit</v-icon>
              </v-btn>
            </td>
            <td class="justify-right">
              <strong class="finance_status parcel">{{props.item.number_this}}/{{props.item.number_total}}</strong>
            </td> 
            <td class="justify-right">
              <strong>{{props.item.date_validity_format}}</strong>
            </td> 
            <td class="justify-right">
              <strong v-if="props.item.finance && props.item.finance.date_receive_format && props.item.finance.status == 2">{{props.item.finance.date_receive_format}}</strong>
              <span v-else> - </span>
            </td>  
            <td class="justify-right">
              <strong style="font-size: 16px;">{{props.item.amount_total | money}}</strong>
            </td>
            <td class="justify-right" style="text-align: left;">
              <span v-if="props.item.finance">
                <strong v-if="props.item.finance.status == 0" class="finance_status canceled">Cancelado</strong>
                <strong v-else-if="props.item.finance.status == 1" class="finance_status unpaid">Aguardando</strong>
                <strong v-else-if="props.item.finance.status == 2" class="finance_status paid">Pago</strong>
              </span>
              <strong v-else-if="data.stage == 3" class="finance_status canceled">Cancelado</strong>
              <strong v-else class="finance_status unpaid">Aguardando</strong>
            </td>
            <td class="justify-right">
              {{props.item.description || ' - '}}
            </td>
            <td class="justify-right" style="text-align: left;">
              <div v-if="props.item.finance_form_payment_id && props.item.form_payment">
                <v-icon :color="props.item.form_payment.color">{{props.item.form_payment.icon}}</v-icon> <br>
                <span>{{props.item.form_payment.name}}</span>
              </div>
              <div v-else>Indefinido</div>
            </td>
            <td class="justify-right">
              <v-btn 
              :disabled="data.stage != 2 || (props.item.finance && props.item.finance.status != 1)" 
              small outline color="green"
              >Receber</v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
    <div v-else style="text-align: center; padding: 20px;">
      <h3>Nenhuma parcela encontrada.</h3>
      <v-btn 
      :disabled="data.stage != 1" 
      outline small color="primary" 
      @click="$WiEditDialog({wiConfig: config.store + '-finance', onSubmit: refresh, data: parseParamsToFinanceModal(data)})" 
      >
        <v-icon>add</v-icon>
        <span>Adicionar Parcela(s)</span>
      </v-btn>
    </div>
  </div>       
</template>
<script>
  export default {
    name: 'BusinessFinance',
    data () {
      return {
        headersFinances: [
          { align: 'center', text: '', value: 'id', sortable: false },
          { align: 'center', text: 'Parcela', value: 'number_this', sortable: false },
          { align: 'center', text: 'Vencimento', value: 'nome_produto', sortable: false },
          { align: 'center', text: 'Pagamento', value: 'date_receive', sortable: false },
          { align: 'center', text: 'Valor', value: 'desc_negocio_produto', sortable: false },
          { align: 'center', text: 'Status', value: 'qtd_produto', sortable: false },
          { align: 'center', text: 'Descrição', value: 'code', sortable: false },
          { align: 'center', text: 'Forma Pgmt.', value: 'valor_unit', sortable: false },
          { align: 'center', text: 'Opções', value: 'finance.status', sortable: false }
        ],
        pagination: {
          sortBy: 'id'
        },
        filters: this.$options.filters
      }
    },
    methods: {
      parseParamsToFinanceModal: function (businessData) {
        return {
          business_id: businessData.id,
          person_id: businessData.person_id,
          amount_initial: businessData.total_liquid,
          show_amount_initial: this.$options.filters.money(businessData.total_liquid),
          status: 4,
          parcel: 1
        }
      }
    },
    props: {
      'config': {
        default: {}
      },
      'data': {
        default: {}
      },
      'refresh': {
        required: true
      }
    }
  }
</script>
<style>
p {
  margin-bottom: 0px !important;
}
.finance_status {
  border-width: 1px;
  border-style: solid;
  padding: 2px 5px;
}
.canceled {
  color: grey;
  background: #eceff1;
}
.unpaid {
  color: #ff6d00;
  background: #f0f4c3;
}
.paid {
  color: green;
  background: #b9f6ca;
}
.parcel {
  color: white;
  background: #000;
}
.pointer {
  cursor: pointer;
}
.v-datatable__actions {
  bottom: 0;
  position: fixed;
  width: 100%;
  left: 0;
  height: 40px;
}
.v-datatable__actions__select {
  height: 30px;
}
.v-datatable__actions__range-controls {
  min-height: 30px;
}
.v-datatable__actions .v-btn {
  height: 30px;
}
</style>
